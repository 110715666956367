<template>
  <div class="flex items-center gap-6 font-2xs self-end whitespace-nowrap text-[11px]">
    <div class="text-typography-disabled">{{ currentYear }} &copy; OrderProtection.com</div>
    <div>
      <a href="https://orderprotection.com/terms" target="_blank" class="text-primary-700">Terms</a>
    </div>
    <div>
      <a href="https://orderprotection.com/privacy-policy" target="_blank" class="text-primary-700">Privacy Policy</a>
    </div>
  </div>
</template>

<script setup lang="ts">
const currentYear = new Date().getFullYear();
</script>
